/**
 * MAP
 */

/* Encyclopedia topic page. */
#map-frame {
  width: 100%;
  height: 400px;
  margin-bottom: $line-height-computed;
  border: 1px solid $gray-lightish;
}

/* Category map page */
.page-header-map {
  border-bottom: 1px solid $hr-border;
  padding-bottom: 0;

  .map-form-title {
    display: inline-block;
    border-bottom: none;
  }

  select {
    margin-left: 0.3em;
    margin-bottom: 0.8em;
    font-weight: bold;
  }
}

.leaflet-bar,
.leaflet-control-layers {
  border-radius: 2px;
}

/* Remove icon, add text. */
a.leaflet-control-layers-toggle {
  background-image: none;
  width: auto;
  height: auto;
  padding: 0.3em 10px;
  font-weight: bold;
  color: $text-color;
  opacity: 1;

  &::before {
    content: "Add overlays in London";
  }
}
.leaflet-touch .leaflet-control-layers-toggle {
  /* Stop the default's 44x44px size, but still make it biggish */
  width: auto;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}
.leaflet-retina a.leaflet-control-layers-toggle {
  background-image: none;
}

/* Based on stuff from https://github.com/Leaflet/Leaflet.label */
.leaflet-label {
  background: rgb(235, 235, 235);
  background: rgba(235, 235, 235, 0.81);
  background-clip: padding-box;
  border-color: #777;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border-style: solid;
  border-width: 4px;
  color: #111;
  display: block;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 1px 6px;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  z-index: 6;
}

.leaflet-label.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-label:before,
.leaflet-label:after {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: none;
  position: absolute;
  top: 5px;
}

.leaflet-label:before {
  border-right: 6px solid black;
  border-right-color: inherit;
  left: -10px;
}

.leaflet-label:after {
  border-left: 6px solid black;
  border-left-color: inherit;
  right: -10px;
}

.leaflet-label-right:before,
.leaflet-label-left:after {
  content: "";
}
