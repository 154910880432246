.site-header {
  @include gradient-with-image(
    "#{$img-path}/site-header.png",
    0deg,
    rgba(50, 20, 0, 0.4),
    rgba(50, 20, 0, 0.1),
    rgba(50, 20, 0, 0),
    rgba(50, 20, 0, 0.1),
    rgba(50, 20, 0, 0.4)
  );
  background-position: 0 0;
  background-repeat: repeat;
  background-color: $site-header-bg;
  border-top: 1px solid $site-header-border-color;
  border-bottom: 1px solid $site-header-border-color;
  margin-top: $supernav-height;
  padding-top: $padding-base-vertical;
  padding-bottom: $padding-base-vertical;

  @media (min-width: $grid-float-breakpoint) {
    padding-top: $padding-large-vertical;
    padding-bottom: $padding-large-vertical;
  }
}

.site-header .container {
  @media (min-width: $grid-float-breakpoint) {
    @include image-retina("#{$img-path}/site-header-pepys", "png", 85px, 104px);
    background-position: ($grid-gutter-width - 5px) 0;
    background-repeat: no-repeat;
    min-height: 104px;
  }
}

.site-header-title {
  color: $site-header-title-color;
  font-family: $font-family-serif;
  font-size: $font-size-base * 1.6;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: 0;

  a {
    color: $site-header-title-color;
  }

  @media (min-width: $grid-float-breakpoint) {
    font-size: $font-size-base * 2.5; // 40px if base is 16px.
    margin-top: 0.2em;
    margin-left: 110px;
  }
}

.site-header-subtitle {
  color: $site-header-subtitle-color;
  font-family: $font-family-serif;
  font-size: $font-size-small;
  font-style: italic;
  margin-bottom: 0;
  margin-left: 4px;

  @media (min-width: $grid-float-breakpoint) {
    font-size: $font-size-large;
    margin-left: 117px;
  }
}
