/**
 * Overriding Bootstrap's default label styles.
 */

/**
 * Use when wanting something to line up with actual labels in a form.
 * Maybe use with a .form-control-static fake input element.
 */
.label-static {
  @extend label;
}

/* Django thing. */
#id_honeypot {
  display: none;
}
