/**
 * To make text line up with li's containing links.
 */
.nav > li {
  &.disabled > a {
    &:hover,
    &:focus {
      cursor: default;
    }
  }
}

.nav-tabs {
  margin-bottom: $line-height-computed;
}
