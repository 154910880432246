/**
 * BREADCRUMBS
 * Overriding/adding to Boostrap's default styles.
 */

.breadcrumb {
  li {
    font-size: $font-size-small;
  }
}
