.search-result {
  margin-bottom: 1.5em;
}

.search-result-title {
  margin-bottom: 0;
  line-height: $line-height-base;

  small {
    font-size: $font-size-small;
    color: $text-muted;
  }
}

.search-result-date,
.search-result-body {
  margin-bottom: 0;
}
