/**
 * The form for posting comments/annotations.
 */

#comment-form {
  margin-top: ($line-height-computed * 0.5);
  margin-left: 30px;
}

.comment-form-title {
  font-size: $font-size-large;
}
.comment-form-notes {
  font-size: $font-size-small;
  margin-top: ($line-height-computed * 0.5);
}

.comments-no-form {
  margin-top: $line-height-computed;
}
