.footnotes,
.letter-footnotes {
  border-top: 1px solid $hr-border;
  font-size: $font-size-base;
  padding-top: ceil($line-height-computed * 0.5);

  & > p,
  & > blockquote > p {
    padding-left: ($grid-gutter-width);
    padding-right: ($grid-gutter-width);
  }

  ol {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  li,
  li p {
    margin-bottom: 1em;
  }

  li p:last-child {
    margin-bottom: 0;
  }
}
