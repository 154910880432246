// Supernav vertical align
// A replacement for Bootstrap's navbar-vertical-align() that we use for the
// vertically thinner supernav bar.
//
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.supernav-vertical-align(30px);` to calculate the appropriate top margin.

@mixin supernav-vertical-align($element-height) {
  margin-top: (($supernav-height - $element-height) * 0.5);
  margin-bottom: (($supernav-height - $element-height) * 0.5);
}
