/****************************************************************************/
/* Custom generic elements. Not overriding Bootstrap. */

.admin {
  background: $admin-bg;
  font-family: $font-family-sans-serif;
  font-size: $font-size-small;
  margin: 0;
}
.admin-links {
  position: absolute;
  right: 10px;

  .admin {
    padding: 3px;
  }
}

img.pull-right {
  margin-left: ($grid-gutter-width * 0.5);
  margin-bottom: 0.3em;
}
img.pull-left {
  margin-right: ($grid-gutter-width * 0.5);
  margin-bottom: 0.3em;
}

/* For 'Read more about this' type links at the ends of things. */
.link-more {
}

/**********************************************************************/
/* For In-Depth Articles and Site News Posts. */
.article-byline {
  font-weight: bold;
}

p.illustration {
  text-align: center;

  img {
    margin-bottom: 0.3em;
  }

  .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }
}

/**********************************************************************
 * Include this style to represent quotes old text, eg diary entries, letters, etc.
 */
.manuscript {
  font-family: $font-family-serif;
}

.manuscript-large {
  font-size: $font-size-largish;

  @media (min-width: $screen-xs-min) {
    font-size: $font-size-large;
  }

  h2 {
    font-size: $font-size-h1;
  }

  blockquote {
    font-size: $font-size-largish;
  }

  .footnotes blockquote {
    font-size: $font-size-base;
  }
}
