/* Some handy little things not in Bootstrap 3. */

.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

/*
.mt-2 {
  margin-top: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
*/
