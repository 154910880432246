/**
 * Updates on Bootstrap's popovers.scss
 */

.popover {
  font-family: $font-family-sans-serif;
  font-size: $font-size-small;
  width: $popover-max-width;

  .thumbnail {
    float: right;
  }
}

.popover-title {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.popover-hasthumbnail {
  .popover-content {
    @include clearfix;
  }
  .thumbnail {
    float: right;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
