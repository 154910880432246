/**
 * SUBCATEGORIES
 * Displayed on encyclopedia category_detail pages.
 */

.subcategories {
  h2 {
    margin-top: 0;
  }

  div {
    /* We want the left-hand edge of the left-hand list to line up with
		 * the list of topics below, so we have to remove the left-hand padding
		 * of its column. And add the extra on to the RHS to keep things OK. */
    padding-left: 0;
    padding-right: $grid-gutter-width;
  }

  ul {
    margin-bottom: 0;
  }
}
