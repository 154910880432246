/**
 * Replacing Bootstrap's img-retina mixin with one that handles @3x images too.
 *
 * Use image-retina instead of Bootstrap's img-retina.
 *
 * If supplised with '../images/decorations' and 'png', assumes these files:
 *
 *  ../images/decorations.png
 *  ../images/decorations@2x.png
 *  ../images/decorations@3x.png
 *
 * Takes slightly different arguments.
 * $file is, eg '../images/decorations'.
 * $ext is the file extension, eg 'png'.
 * $width is the pixel width of the 1x image.
 * $height is the pixel height of the image.
 */

// Note that the spelling of `min--moz-device-pixel-ratio` is intentional.
@mixin image-retina($file, $ext, $width, $height) {
  background-image: url(if(
    $bootstrap-sass-asset-helper,
    twbs-image-path("#{$file}.#{$ext}"),
    "#{$file}.#{$ext}"
  ));
  background-size: $width $height;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    background-image: url(if(
      $bootstrap-sass-asset-helper,
      twbs-image-path("#{$file}@2x.#{$ext}"),
      "#{$file}@2x.#{$ext}"
    ));
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3),
    only screen and (min--moz-device-pixel-ratio: 3),
    only screen and (-o-min-device-pixel-ratio: 3/1),
    only screen and (min-device-pixel-ratio: 3),
    only screen and (min-resolution: 288dpi),
    only screen and (min-resolution: 3dppx) {
    background-image: url(if(
      $bootstrap-sass-asset-helper,
      twbs-image-path("#{$file}@3x.#{$ext}"),
      "#{$file}@3x.#{$ext}"
    ));
  }
}
