/**
 * ENTRY
 * For diary entries.
 */

.entries {
  .entry {
    margin-bottom: 2em;
  }
}
